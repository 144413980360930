import React, { useState } from 'react';
import comparisonwgg from "../images/comparison_wgg.png";
import Berlin from "../images/Berlin.png";
import Hamburg from "../images/Hamburg.png";
import Munich from "../images/Munich.png";
import Wuerzburg from "../images/Wuerzburg.png";
import wgg_header from "../images/wgg_header.png";

const WggScrape = () => {
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const openFullScreen = (imageSrc) => {
    setFullScreenImage(imageSrc);
    document.documentElement.requestFullscreen();
  };

  const closeFullScreen = () => {
    setFullScreenImage(null);
    document.exitFullscreen();
  };

  return (
    <>
      <div className="bg-white py-8 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        {/* Blog article content */}
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl text-center leading-9 font-extrabold text-gray-900 flex items-center justify-center">
            Wg-Gesucht.de Data Analysis
          </h2>

          <div className="mt-4 flex items-center justify-center">
            <img src={wgg_header} alt="wgg_header Wgg" />
          </div>

          <p className='text-justify mt-4'>
            I scraped entries for shared rooms from the website wg-gesucht.de using Beautiful Soup and conducted a data analysis based on room size, 
            price, and price per square meter. I performed this analysis for four different cities and then compared the cities to each other.

            The purpose of the analysis was to examine the variations in room sizes, prices, and price 
            per square meter across different cities in the dataset. By collecting this information, 
            I aimed to gain insights into the housing market and the affordability of shared accommodations in 
            these locations.

            Overall, this analysis provides valuable insights into the variations in room sizes, prices, and price per 
            square meter across different cities in the dataset. It can assist individuals seeking shared accommodations 
            in making informed decisions based on their preferences and financial constraints.
          </p>

          {/* Other content */}
          <div>
            <h3 className="text-2xl text-center leading-9 font-extrabold text-gray-900 mt-8 flex items-center justify-center">
                Comparison of Berlin, Hamburg, Munich & Würzburg
            </h3>
            <div className="mt-4 flex items-center justify-center">
                <img src={comparisonwgg} alt="Comparison Wgg" onClick={() => openFullScreen(comparisonwgg)}/>
            </div>
            <div className="flex items-center justify-center">
              <p className='text-[12px]'>ℹ️ Click to maximize.</p>
            </div>
            </div>
              
          
          {/* Image galleries */}
          <div>
            {/* Berlin */}
            <h3 className="text-2xl text-center leading-9 font-extrabold text-gray-900 mt-8 flex items-center justify-center">
              Detailed view of Berlin
            </h3>
            <div className="mt-4 flex items-center justify-center">
              <img src={Berlin} alt="Berlin Wgg" onClick={() => openFullScreen(Berlin)} />
            </div>
            <div className="flex items-center justify-center">
              <p className='text-[12px]'>ℹ️ Click to maximize.</p>
            </div>
          </div>

          <div>
            {/* Hamburg */}
            <h3 className="text-2xl text-center leading-9 font-extrabold text-gray-900 mt-8 flex items-center justify-center">
              Detailed view of Hamburg
            </h3>
            <div className="mt-4 flex items-center justify-center">
              <img src={Hamburg} alt="Hamburg Wgg" onClick={() => openFullScreen(Hamburg)} />
            </div>
            <div className="flex items-center justify-center">
              <p className='text-[12px]'>ℹ️ Click to maximize.</p>
            </div>
          </div>

          {/* Repeat for Munich and Wuerzburg */}
          {/* Munich */}
          <div>
            <h3 className="text-2xl text-center leading-9 font-extrabold text-gray-900 mt-8 flex items-center justify-center">
                Detailed view of Munich
            </h3>
            <div className="mt-4 flex items-center justify-center">
                <img src={Munich} alt="Munich Wgg" onClick={() => openFullScreen(Munich)}/>
            </div>
            <div className="flex items-center justify-center">
              <p className='text-[12px]'>ℹ️ Click to maximize.</p>
            </div>
          </div>

      
          {/* Wuerzburg */}

          <div>
            <h3 className="text-2xl text-center leading-9 font-extrabold text-gray-900 mt-8 flex items-center justify-center">
                Detailed view of Würzburg
            </h3>
            <div className="mt-4 flex items-center justify-center">
                <img src={Wuerzburg} alt="Wuerzburg Wgg" onClick={() => openFullScreen(Wuerzburg)}/>
            </div>
            <div className="flex items-center justify-center">
              <p className='text-[12px]'>ℹ️ Click to maximize.</p>
            </div>
          </div>

          {/* Full screen image */}
          {fullScreenImage && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center" onClick={closeFullScreen}>
              <div className="max-w-4/5">
                <img src={fullScreenImage} alt="Full Screen" className="max-h-full max-w-full" style={{width: "80vw"}} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WggScrape;

import { Navbar, About, Footer, Project, Experience, Contact, Error, StockComparison, WggScrape} from "./components";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import './App.css';


const App = () => {
  return (
    <div>
      <Router >
        <Navbar />

          <Routes>
            <Route path="/" element={<><About /><Project /><Experience /><Contact /></>} />
            <Route path="/stock" element={<StockComparison />} />
            <Route path="/wggscrape" element={<WggScrape />} />
            <Route path="/*" element={<Error />} />
            
          </Routes>

          <Footer />
      
      </Router>
    </div>
  );
}

export default App;

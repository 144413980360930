import React from 'react';
import cvjan from '../images/CV_Jan_Steinbacher.pdf';
import './About.css';

const About = () => {
  const handleDownloadCV = () => {
    window.open(cvjan, '_blank');
  };

  return (
    <section id="about">
      <div className="lg:ml-64 lg:mr-64">
        <div className="content">
          <div className="image">
            <div className="home__img"></div>
          </div>
          <div className="text">
            <h2>About Me</h2>
            <p>
            Hi, my name is Jan and I am currently enrolled in the Information Systems Master's program at the University of Würzburg. My focus here is on business analytics and e-business. 
            I am also working as a student trainee for the Berlin-based startup BuchhaltungsButler. 
            </p>
            <button className="btn" onClick={handleDownloadCV}>
              View CV
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import useReadingProgress from "../hooks/useReadingProgress"


function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const completion = useReadingProgress();
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <Link to="/" className="nav-logo">
            Jan Steinbacher
          </Link>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
            <a href="#about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </a>
            </li>
            <li className="nav-item">
            <a href="#projects"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Projects
                </a>
            </li>
            <li className="nav-item">
            <a href="#experience"
            
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Experience
                </a>
            </li>
            <li className="nav-item">
            <a href="#contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact
                </a>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
        <span
        style={{ transform: `translateX(${completion - 100}%)` }}
        className="absolute bg-[#00c49a] h-1 w-full bottom-0"
      />
      </nav>
    </>
  );
}

export default NavBar;
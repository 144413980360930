import React from 'react';
import ExperienceBox from './ExperienceBox';
import './Experience.css';
import AI from '../images/ai.png';
import Excel from '../images/excel.png';
import Python from '../images/python.png';
import GA4 from '../images/ga4.png';
import GAds from '../images/gads.png';

const Experience = () => {
  const experiences = [
    
    {
      title: 'Excel',
      image: Excel,
      text: 'I use Excel and Google Sheets on a daily basis. I can create complex spreadsheets to analyze data.',
      
    },
    
    {
      title: 'Python',
      image: Python,
      text: 'I used Python and FastAPI to create the project of the solvency checker.',
      
    },
    {
      title: 'Machine Learning',
      image: AI,
      text: 'I worked with Machine Learning in my Bachelor Paper to predict stock prices.',
      
    },

    {
      title: 'Google Analytics',
      image: GA4,
      text: 'I work with GA4 to analyze user behavior on Websites and Webapps.',
      
    },

    {
      title: 'Google Ads',
      image: GAds,
      text: 'I am working with GoogleAds to increase the numbers of new Leads.',
      
    },
    // Add more experiences here
  ];

  return (
    <section className='lg:mt-32 mb-32' id="experience">
      <h2 className='text-3xl text-left ml-8 lg:ml-64'>Experience</h2>
      <p className='ml-8 lg:ml-64'>I worked with the following tools.</p>
    <div className="grid-container flex overflow-x-auto scroll-snap-type-x-mandatory mb-8 ml-8 mr-8 lg:ml-64 lg:mr-64 ">

      <div className="grid-inner-container flex space-x-2 lg:space-x-8 ">
        
        {experiences.map((experience, index) => (
          
          <ExperienceBox key={index} {...experience} />
          
        ))}
      </div>
    </div>
    </section>
  );
};

export default Experience;

import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3ciwe12",
        "template_myx6zob",
        form.current,
        "MgYiEo5wk36UqOQoE"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact" className="flex justify-center items-center mb-8">
      <div className="w-full max-w-md">
        <h2 className="text-center text-2xl font-bold mb-8">Contact me!</h2>
        <div className="flex justify-center mb-4">
          <a href="mailto:jan.steinbacher@gmail.com">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="2x"
              className="text-gray-700 hover:text-gray-900"
            />
          </a>
          <a href="https://www.linkedin.com/in/jan-steinbacher-513a291a1/">
            <FontAwesomeIcon
              icon={faLinkedin}
              size="2x"
              className="text-gray-700 hover:text-gray-900 ml-4"
            />
          </a>
        </div>

        <div className="bg-white rounded-md p-8">
          {isFormSubmitted ? (
            <div className="bg-green-200 text-green-800 rounded-md py-2 px-4 mb-4">
              Message was sent!
            </div>
          ) : (
            <form ref={form} onSubmit={sendEmail}>
              <div className="mb-4 ">
                <label className="font-bold">Name</label>
                <input
                  type="text"
                  name="user_name"
                  className="border border-gray-300 rounded-md py-2 px-4 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="font-bold">Email</label>
                <input
                  type="email"
                  name="user_email"
                  className="border border-gray-300 rounded-md py-2 px-4 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="font-bold">Message</label>
                <textarea
                  name="message"
                  className="border border-gray-300 rounded-md py-2 px-4 w-full"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 px-4 w-full"
              >
                Send
              </button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;

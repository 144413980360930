import React from 'react';
import cryptobanks from "../images/Cryptobanks_Startpage.png";
import chromePlugin from "../images/chrome_plugin.png";
import solvencyCheckerVideo from "../images/solvency_checker.mp4";
import solvencyCheckerIMG from "../images/solvencyChecker.png"
import comparisonwgg from "../images/comparison_wgg.png"
import cryptobanksFinal from "../images/Cryptobanks.png"

import './Project.css';

const Projects = () => {

  const handlesolvencyCheckerVideo = () => {
    window.open(solvencyCheckerVideo, '_blank');
  };

  return (
    <section className='lg:mt-24' id="projects">
      <div className="ml-8 mr-8 lg:ml-64 lg:mr-64 mb-12">
        <h2 className="section-heading">Projects</h2>
        <p className="section-subheading">These are some of my projects which I did in university or in my free time.</p>
        <div className="grid-container">

        <div className="grid-item ">
          <h3>Cryptobanks - E-Learning Platform for Cryptoassets</h3>
          <div className="lg:h-36 md:h-36 sm:h-32 flex items-center justify-center m-12">
            <img src={cryptobanksFinal} alt="Cryptobanks E-Learning Platform" className="max-h-full max-w-full" />
          </div>
          <div className="p-div">
            <p>I built this platform in a team as part of a university project to provide bank employees with a comprehensive understanding of the integration of cryptoassets into conventional banking systems.</p>
          </div>
          <a href="https://bucolic-stardust-2f7b6f.netlify.app" className="btn">See more...</a>
        </div>

        <div className="grid-item ">
            <h3>Data Analysis of Wg-gesucht.de</h3>
            <div className="lg:h-64 flex items-center justify-center mb-4">
            <img src={comparisonwgg} alt="Comparison Wgg" />
            </div>
            <div className="p-div">
            <p>Scrape of accomodation entries on wg-gesucht.de and analysis of 4 cities.</p>
            </div>
            <a href="/wggscrape" className="btn">See more...</a>
          </div>


          <div className="grid-item">
            <h3>solvency checker tool</h3>
            <div className="lg:h-64 flex items-center justify-center mb-4">
            <img src={solvencyCheckerIMG} alt="Project 1" />
            </div>
            <div className="p-div">
            <p>A solvency checker tool for your next potential employer!</p>
            </div>
            <button className="btn" onClick={handlesolvencyCheckerVideo}>
              See more...
            </button>
          </div>


          <div className="grid-item">
            <h3>Cryptobanks</h3>
            <div className="lg:h-64 flex items-center justify-center mb-4">
            <img src={cryptobanks} alt="Project 2" />
            </div>
            <div className="p-div">
            <p>Learning platform for information on how traditional banks can implement crypto and blockchain.</p>
            </div>
            <a href="https://peaceful-flan-bf1d8b.netlify.app/" className="btn">See more...</a>
          </div>

          
          <div className="grid-item ">
            <h3>Chrome-Plugin Email-Scraper</h3>
            <div className="lg:h-64 flex items-center justify-center mb-4">
            <img src={chromePlugin} alt="Chrome-Plugin Email-Scraper" />
            </div>
            <div className="p-div">
            <p>A Chrome Plugin which can be used to scrape all emails from the current website that are available in the HTML text.</p>
            </div>
            <a href="https://github.com/jansteinbacher/chromeemailscraper" className="btn">See more...</a>
          </div>
          
          
        </div>
      </div>
    </section>
  );
}

export default Projects;

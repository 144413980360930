import React from 'react';


const StockComparison = () => {
  



  

  

  return (
    <div >
      Stock
    </div>
  );
};

export default StockComparison;
